import React from 'react';
import { useApi } from 'services/axios';
import { SelectDropdown, Loader, RadioGroup } from 'components';
import { useFormContext, Controller } from 'react-hook-form';

import styles from './styles.module.css';
import { useValidation } from 'helpers/validation';
import { useTranslation } from 'react-i18next';

const Dsa_Step4 = () => {
  const [abroadData, setAbroadData] = React.useState(null);
  const [countryData, setCountryData] = React.useState(null);
  const { control, formState, watch } = useFormContext();

  const [error, setError] = React.useState(null);
  const { api } = useApi();
  const validation = useValidation();
  const { t } = useTranslation('common');
  const options = t('radioOptions', { returnObjects: true });
  const abroadAnswer = watch('step4a.abroadRadio');
  // const countryAnswer = watch('step4b.countryRadio');


  const fetchData = async () => {
    try {
      const abroadResponse = await api.get(`/dsa_abroad`);
      const countryResponse = await api.get(`/dsa_country`);

      setAbroadData(abroadResponse.data);
      setCountryData(countryResponse.data);

    } catch (err) {
      console.error('Error fetching entry: ', err);
      setError(err);
    }
  };

  React.useEffect(() => {
    if (api && !abroadData) {
      fetchData();
    }
  }, [api]);

  return (
    <div className="card-body">
      <div className={styles.dsaInputsWrapper} style={{ marginTop: '40px' }}>
        <Controller
          name="step4a.abroadRadio"
          control={control}
          rules={{ ...validation.required }}
          render={({ field }) => (
            <RadioGroup
              label={t('dsa.steps.4.datatransaction')}
              name={field.name}
              options={options}
              onChange={field.onChange}
              value={field.value}
              error={formState.errors?.step4a?.abroadRadio}
              customColor="#fa8231"
            />
          )}
        />

        {!abroadData && abroadAnswer === 'yes' && <Loader />}
        {abroadData && abroadAnswer === 'yes' && (
          <Controller
            control={control}
            name="step4a.abroadDropdown"
            rules={{ ...validation.required }}
            render={({ field }) => {
              return (
                <SelectDropdown
                  options={abroadData.map((option) => ({
                    value: option.DataAbroadId,
                    label: option.DataAbroadName,
                  }))}
                  label="Empfänger"
                  isCreatable
                  isMulti
                  onChange={field.onChange}
                  value={field.value}
                  error={formState.errors?.step4a?.abroadDropdown}
                  name={field.name}
                  customColor="#fa8231"
                />
              );
            }}
          />
        )} 

      {/* <Controller
          name="step4b.countryRadio"
          control={control}
          rules={{ ...validation.required }}
          render={({ field }) => (
            <RadioGroup
              label={t('dsa.steps.4.countrytransaction')}
              name={field.name}
              options={options}
              onChange={field.onChange}
              value={field.value}
              error={formState.errors?.step4b?.countryRadio}
              customColor="#fa8231"
            />
          )}
        />

        {!countryData && countryAnswer === 'yes' && <Loader />}
        {countryData && countryAnswer === 'yes' && (
          <Controller
            control={control}
            name="step4b.countryDropdown"
            rules={{ ...validation.required }}
            render={({ field }) => {
              return (
                <SelectDropdown
                  options={countryData.map((option) => ({
                    value: option.CountryId,
                    label: option.CountryName,
                  }))}
                  label="Land"
                  isCreatable
                  isMulti
                  onChange={field.onChange}
                  value={field.value}
                  error={formState.errors?.step4b?.countryDropdown}
                  name={field.name}
                  customColor="#fa8231"
                />
              );
            }}
          />
        )} */}
      </div>
    </div>
  );
};

export { Dsa_Step4 };
