import React from 'react';
import ReactFlow, { MarkerType } from 'reactflow';
import 'reactflow/dist/style.css';
import { useTranslation } from 'react-i18next';

const CustomNode = ({ data }) => {
  const { label, icon, isMainStep, isCompleted, onClick, description } = data;
  
  const backgroundColor = isMainStep 
    ? 'linear-gradient(135deg, #ffffff 0%, #f0f3f5 100%)'
    : (isCompleted ? 'linear-gradient(135deg, #e1fae5, #d4edda)' : 'linear-gradient(135deg, #fde2e1, #f8d7da)');

  const borderColor = isMainStep 
    ? '#ccd2d9' 
    : (isCompleted ? '#20bf6b' : '#eb3b5a');

  const iconSize = isMainStep ? '40px' : '30px';

  return (
    <div 
      className="morph_variation1"
      style={{
        width: '250px',
        height: '130px', // Taller node
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: onClick ? 'pointer' : 'default',
        position: 'relative',
        borderRadius: '20px',
        background: backgroundColor,
        border: `1px solid ${borderColor}`,
        boxShadow: '2px 2px 10px rgba(0,0,0,0.05)',
        textAlign: 'center',
        transition: 'transform 0.2s, box-shadow 0.2s',
        padding: '10px', // Some padding for spacing
      }}
      onClick={onClick}
      onMouseEnter={e => {e.currentTarget.style.boxShadow = '4px 4px 15px rgba(0,0,0,0.1)';}}
      onMouseLeave={e => {e.currentTarget.style.boxShadow = '2px 2px 10px rgba(0,0,0,0.05)';}}
    >
      {icon && (
        <img 
          src={icon} 
          alt="icon" 
          style={{ width: iconSize, height: iconSize, marginBottom: '6px' }} 
        />
      )}
      <span 
        style={{ 
          fontWeight: isMainStep ? '600' : '500', 
          fontSize: isMainStep ? '16px' : '14px',
          color: '#34495e',
          marginBottom: '4px'
        }}
      >
        {label}
      </span>
      {description && (
        <span 
          style={{
            fontSize: '12px',
            color: '#555',
            lineHeight: '1.2',
            padding: '0 10px' // Some horizontal padding for nicer text layout
          }}
        >
          {description}
        </span>
      )}
      {isCompleted && !isMainStep && (
        <div 
          style={{ 
            position: 'absolute', 
            top: '8px', 
            right: '8px', 
            fontSize: '14px', 
            color: '#20bf6b' 
          }}
        >
          ✓
        </div>
      )}
    </div>
  );
};

const nodeTypes = { custom: CustomNode };

const ProcessDiagram = ({ secondTableData, hasStandortbestimmung }) => {
  const { t } = useTranslation();

  // Map createlink to icon paths
  const docIconMap = {
    verzeichnis: '/icon-verzeichnis.svg',
    br: '/icon-br.svg',
    Datenschutzfolgeabschaetzung: '/icon-dsfa.svg',
    toms: '/icon-tom.svg',
    dsa: '/icon-dse.svg',
    advv: '/icon-advv.svg',
  };

  const iconMap = {
    standortbestimmung: '/icon-sb.svg',
    audit: '/icon-br.svg',
    finished: '/icon-tom.svg', 
  };

  // Start linear layout
  const nodes = [
    {
      id: 'step-1',
      type: 'custom',
      data: {
        label: t('Standortbestimmung'),
        icon: iconMap.standortbestimmung,
        isMainStep: true,
        // Add a description if you'd like, or remove if not needed
        description: hasStandortbestimmung 
          ? t('Create your initial Standortbestimmung, already done!')
          : t('Create your initial Standortbestimmung to begin.'),
        onClick: !hasStandortbestimmung ? () => (window.location.href = '/Standortbestimmung') : undefined
      },
      position: { x: 0, y: 0 },
    }
  ];

  const edges = [];

  let lastNodeId = 'step-1';
  let currentX = 300;
  let currentY = 0;

  if (hasStandortbestimmung) {
    // Place documents linearly from left to right
    secondTableData.forEach((item) => {
      const isCompleted = item.completed === 'Ja';
      const labelText = t(item.bereich);
      const navigateUrl = isCompleted ? `/${item.overviewlink}` : `/${item.createlink}`;
      const subIcon = docIconMap[item.createlink] || '/icon-verzeichnis.svg';

      // We'll assume `description` is available in secondTableData.
      // If not, use a placeholder or something else.
      const docDescription = t(item.description || 'No description available');

      const docNodeId = `doc-${item.actionId}`;
      nodes.push({
        id: docNodeId,
        type: 'custom',
        data: {
          label: labelText,
          icon: subIcon,
          isMainStep: false,
          isCompleted,
          description: docDescription,
          onClick: () => (window.location.href = navigateUrl),
        },
        position: { x: currentX, y: currentY },
      });

      edges.push({
        id: `e-${lastNodeId}-${docNodeId}`,
        source: lastNodeId,
        target: docNodeId,
        type: 'smoothstep',
        markerEnd: { type: MarkerType.ArrowClosed },
        style: { stroke: '#ccc', strokeWidth: 1.5 },
      });

      lastNodeId = docNodeId;
      currentX += 300; // space nodes horizontally
    });

    // After documents, place Audit Documents
    const auditNodeId = 'step-audit';
    nodes.push({
      id: auditNodeId,
      type: 'custom',
      data: {
        label: t('Audit Documents'),
        icon: iconMap.audit,
        isMainStep: true,
        description: t('Review all created documents for accuracy and compliance.'),
      },
      position: { x: currentX, y: currentY },
    });
    edges.push({
      id: `e-${lastNodeId}-${auditNodeId}`,
      source: lastNodeId,
      target: auditNodeId,
      type: 'smoothstep',
      markerEnd: { type: MarkerType.ArrowClosed },
      style: { stroke: '#ccc', strokeWidth: 1.5 },
    });
    lastNodeId = auditNodeId;
    currentX += 300;

    // Finished step
    const finishedNodeId = 'step-finished';
    nodes.push({
      id: finishedNodeId,
      type: 'custom',
      data: {
        label: t('Finished'),
        icon: iconMap.finished,
        isMainStep: true,
        description: t('All steps are completed. Your process is finished!'),
      },
      position: { x: currentX, y: currentY },
    });

    edges.push({
      id: `e-${lastNodeId}-${finishedNodeId}`,
      source: lastNodeId,
      target: finishedNodeId,
      type: 'smoothstep',
      markerEnd: { type: MarkerType.ArrowClosed },
      style: { stroke: '#ccc', strokeWidth: 1.5 },
    });
  }

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        margin: 0,
        padding: 0,
        border: 'none',
        position: 'absolute',
        top: 0,
        left: 0,
        overflow: 'hidden',
        background: 'linear-gradient(135deg, #ffffff 0%, #f9fafb 100%)',
      }}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        fitView
        nodeTypes={nodeTypes}
        nodesDraggable={false}
        nodesConnectable={false}
        panOnDrag={false}
        zoomOnScroll={false}
        zoomOnPinch={false}
        zoomOnDoubleClick={false}
      />
    </div>
  );
};

export default ProcessDiagram;