import React, { useState } from 'react';
import { Textarea } from 'components/Textarea';
import { RadioGroup } from 'components/RadioGroup';
import { useFormContext, Controller } from 'react-hook-form';
import { useValidation } from 'helpers/validation';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

const Verzeichnis_Step7 = () => {
  const { control, formState, unregister, watch } = useFormContext();
  const { t } = useTranslation('common');
  const validation = useValidation();

  const options = t('radioOptions', { returnObjects: 'true' });

  // // Watch the value of the radio group for automated decisions
  // const einzelentscheideValue = watch('step7.einzelentscheide');

  // // Unregister highRiskProfiling and remarks if automated decisions is 'no'
  // React.useEffect(() => {
  //   if (einzelentscheideValue === 'no') {
  //     unregister('step7.highRiskProfiling');
  //     unregister('step7.bemerkungen');
  //   }
  // }, [einzelentscheideValue, unregister]);

  return (
    <div className="card-body">
      <h4>Profiling und automatisierte Einzelentscheide</h4>
      <div className={styles.steptext}>
        {t('verzeichnis.steps.7.description')}
      </div>
      <div className={styles.verzeichnisInputsWrapper}>
        {/* Automatisierte Einzelentscheidungen */}
        <Controller
          name="step7.einzelentscheide"
          control={control}
          rules={{ ...validation.required }}
          render={({ field }) => (
            <RadioGroup
              label={t('verzeichnis.steps.7.inputs.automatedDecisions')}
              name="einzelentscheide"
              options={options}
              tooltip={t('verzeichnis.steps.7.inputs.automatedDecisionsTooltip')}
              onChange={field.onChange}
              value={field.value}
              error={formState.errors?.step7?.einzelentscheide}
              tooltipText={t('verzeichnis.steps.7.inputs.automatedDecisionsTooltip')}
            />
          )}
        />

        {/* High Risk Profiling */}
        <Controller
              name="step7.highRiskProfiling"
              control={control}
              rules={{ ...validation.required }}
              render={({ field }) => (
                <RadioGroup
                  label={t('verzeichnis.steps.7.inputs.highRiskProfiling')}
                  name="highRiskProfiling"
                  options={options}
                  tooltip={t('verzeichnis.steps.7.inputs.highRiskProfilingTooltip')}
                  onChange={field.onChange}
                  value={field.value}
                  error={formState.errors?.step7?.highRiskProfiling}
                  tooltipText={t('verzeichnis.steps.7.inputs.highRiskProfilingTooltip')}
                />
              )}
            />

            {/* Remarks */}
            <Controller
              name="step7.bemerkungen"
              control={control}
              render={({ field }) => (
                <Textarea
                  label={t('verzeichnis.steps.7.inputs.remarks')}
                  id="verzeichnis-bemerkungen"
                  error={formState.errors?.step7?.bemerkungen}
                  {...field}
                />
              )}
            />
      </div>
    </div>
  );
};

export { Verzeichnis_Step7 };