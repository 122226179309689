import React from 'react';
import { useApi } from 'services/axios';
import { useTranslation } from 'react-i18next';

const SimpleList = ({ categoryData }) => {
  return (
    <div>
      {Object.entries(categoryData.categories).map(([category, data]) => (
        <div key={category} style={{ marginBottom: '10px' }}>
          <p style={{ fontWeight: 'bold', display: 'inline' }}>
            {category}:
          </p>
          <span>
            {` ${data.preset.map(item => item.label).join(', ')}`}
            {data.custom && data.custom.length > 0
              ? `, ${data.custom.map(item => item.label).join(', ')}`
              : ''}
          </span>
        </div>
      ))}
    </div>
  );
};



const Dsa_Step2 = () => {
  const [categoryData, setCategoryData] = React.useState(null);
  const { api } = useApi();
  const [error, setError] = React.useState(null);
  const { t } = useTranslation('common');

  const fetchData = async () => {
    try {
      const categoryResponse = await api.get(`/verzeichnis_categorydata`);
      console.log('Fetched category data:', categoryResponse.data);
      setCategoryData(categoryResponse.data);
    } catch (err) {
      console.error('Error fetching entry: ', err);
      setError(err);
    }
  };

  React.useEffect(() => {
    if (api && !categoryData) {
      fetchData();
    }
  }, [api, categoryData]);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    }}>
      <h4 style={{ fontWeight: 'bold', marginBottom: '15px' }}>{t('dsa.steps.2.description')}</h4>
      <p style={{ marginBottom: '20px' }}>{t('dsa.steps.2.text')}</p>

      {error && <div style={{ color: 'red', marginTop: '20px' }}>Error loading category data: {error.message}</div>}
      {categoryData ? (
        <SimpleList categoryData={categoryData} />
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export { Dsa_Step2 };