import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { DATA_ABROAD, PURPOSES } from './constants';
import { TITLES } from 'Programme/Toms/TomsDetailedView';

const DetailedViewPdf = ({ data, selectedTomsData, categoryData }) => {
  const { companyInfo } = {
    companyInfo: data.step1.usesDefaultCompany ? data.companyInfo : data.step1.customCompany,
  };

  const pdfStyles = StyleSheet.create({
    pageContainer: { 
      paddingVertical: 35, 
      paddingHorizontal: 35, 
      fontFamily: 'Helvetica',
      fontSize: 11
    },
    section: { 
      marginBottom: 5 
    },
    title: {
      fontSize: 18,
      fontWeight: 'bold',
      marginBottom: 10
    },
    heading: { 
      fontSize: 14, 
      fontWeight: 'bold', 
      marginBottom: 10,
      marginTop: 5
    },
    paragraph: { 
      marginBottom: 2,
      lineHeight: 1.6
    },
    listItem: { 
      marginBottom: 2,
      paddingLeft: 15
    },
    bulletPoint: {
      position: 'absolute',
      left: 0
    },
    companyDetails: {
      marginTop: 10,
      marginBottom: 5
    },
    categoryContainer: {
      marginLeft: 15,
      marginTop: 2
    },
    categoryLabel: {
      fontWeight: 'bold',
      marginRight: 5
    },
    tomsContainer: {
      width: '48%',
      marginBottom: 5
    },
    tomsGrid: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    },
    tomsTitle: {
      fontSize: 12,
      fontWeight: 'bold',
      marginBottom: 5
    },
    furtherInfo: {
      marginTop: 10,
      marginBottom: 5
    },
    lastEdited: {
      marginTop: 10,
      fontSize: 10
    }
  });

  return (
    <Document>
      <Page size="A4" style={pdfStyles.pageContainer}>
        {/* Titel */}
        <View style={pdfStyles.section}>
          <Text style={pdfStyles.title}>
            Datenschutzerklärung {companyInfo?.CompanyName}
          </Text>
        </View>

        {/* Einleitung */}
        <View style={pdfStyles.section}>
          <Text style={pdfStyles.paragraph}>
            In dieser Datenschutzerklärung erläutern wir, die {companyInfo?.CompanyName} (nachfolgend wir oder uns), wie wir Personendaten erheben und sonst bearbeiten. Das ist keine abschliessende Beschreibung; allenfalls regeln andere Datenschutzerklärungen oder allgemeine Geschäftsbedingungen, Teilnahmebedingungen und ähnliche Dokumente spezifische Sachverhalte.
          </Text>
          <Text style={pdfStyles.paragraph}>
            Wenn Sie uns Personendaten anderer Personen (z.B. Familienmitglieder, Daten von Arbeitskollegen) zur Verfügung stellen, stellen Sie bitte sicher, dass diese Personen die vorliegende Datenschutzerklärung kennen und teilen Sie uns deren Personendaten nur mit, wenn Sie dies dürfen und wenn diese Personendaten korrekt sind.
          </Text>
        </View>

        {/* Rechtsgrundlage */}
        <View style={pdfStyles.section}>
          <Text style={pdfStyles.heading}>Rechtsgrundlage</Text>
          <Text style={pdfStyles.paragraph}>
            {data.step1.law.value === 1
              ? "Diese Datenschutzerklärung ist auf die Anforderungen des Schweizer Datenschutzgesetzes (DSG) ausgelegt."
              : data.step1.law.value === 2
              ? "Diese Datenschutzerklärung ist auf die Anforderungen der EU-Datenschutz-Grundverordnung (DSGVO) ausgelegt."
              : "Diese Datenschutzerklärung ist auf die Anforderungen der EU-Datenschutz-Grundverordnung (DSGVO) und des Schweizer Datenschutzgesetzes (DSG) ausgelegt. Ob und inwieweit diese Gesetze anwendbar sind, hängt jedoch vom Einzelfall ab."}
          </Text>
        </View>

        {/* Verantwortlicher */}
        <View style={pdfStyles.section}>
          <Text style={pdfStyles.heading}>Verantwortlicher</Text>
          <Text style={pdfStyles.paragraph}>
            Verantwortlich für die Datenbearbeitungen, die wir hier beschreiben, ist die {companyInfo?.CompanyName}. Wenn Sie datenschutzrechtliche Anliegen haben, können Sie uns diese an folgende Kontaktadresse mitteilen:
          </Text>
          <View style={pdfStyles.companyDetails}>
            <Text>{companyInfo?.CompanyName}</Text>
            <Text>{companyInfo?.CompanyStreet}</Text>
            <Text>{companyInfo?.CompanyZIP} {companyInfo?.CompanyCity}</Text>
            <Text>Telefon: {companyInfo?.CompanyPhone}</Text>
          </View>
        </View>

        {/* Erhebung */}
        <View style={pdfStyles.section}>
          <Text style={pdfStyles.heading}>Erhebung und Bearbeitung von Personendaten</Text>
          <Text style={pdfStyles.paragraph}>
            Folgende Daten können von uns im Rahmen der rechtlichen Vorgaben bearbeitet werden:
          </Text>
          {categoryData && (
            <View style={pdfStyles.categoryContainer}>
              {Object.entries(categoryData.categories).map(([category, data]) => (
                <View key={category} style={pdfStyles.paragraph}>
                  <Text>
                    <Text style={pdfStyles.bulletPoint}>•</Text>
                    <Text style={pdfStyles.categoryLabel}>{category}:</Text>
                    {`${data.preset.map(item => item.label).join(', ')}${
                      data.custom && data.custom.length > 0
                        ? `, ${data.custom.map(item => item.label).join(', ')}`
                        :  ''
                    }`}
                  </Text>
                </View>
              ))}
            </View>
          )}
        </View>

        {/* Zweck */}
        <View style={pdfStyles.section}>
          <Text style={pdfStyles.heading}>Zweck der Datenbearbeitungen</Text>
          <Text style={pdfStyles.paragraph}>
            Wir verwenden die von uns erhobenen Personendaten in erster Linie, um unsere Verträge mit unseren Kunden und Geschäftspartnern abzuschliessen und abzuwickeln, sowie um unseren gesetzlichen Pflichten im In- und Ausland nachzukommen. Darüber hinaus bearbeiten wir Personendaten von Ihnen und weiteren Personen, soweit erlaubt und es uns als angezeigt erscheint, auch für folgende Zwecke:
          </Text>
          {data.step3.purpose?.preset?.map((point, i) => (
            <Text key={i} style={pdfStyles.listItem}>
              <Text style={pdfStyles.bulletPoint}>•</Text>
              {PURPOSES[point.value - 1]}
            </Text>
          ))}
        </View>

        {/* Datenbekanntgabe an Dritte */}
        <View style={pdfStyles.section}>
          <Text style={pdfStyles.heading}>Datenbekanntgabe and Dritte</Text>
          <Text style={pdfStyles.paragraph}>
            {data.step4a.abroadRadio === "no"
              ? "Wir übermitteln keine Daten an Dritte. Sobald dies der Fall werden sollte, werden wir an dieser Stelle darüber informieren."
              : "Wir geben im Rahmen unserer geschäftlichen Aktivitäten und der hier erwähnten Zwecke der Bearbeitung, soweit erlaubt und es uns als angezeigt erscheint, auch Dritten bekannt. Sei es, weil sie diese für uns bearbeiten oder für ihre eigenen Zwecke verwenden. Dabei handelt es sich insbesondere um folgende Stellen:"}
          </Text>
          {data.step4a.abroadRadio === "yes" && data.step4a.abroadDropdown?.preset?.map((point, i) => (
            <Text key={i} style={pdfStyles.listItem}>
              <Text style={pdfStyles.bulletPoint}>•</Text>
              {DATA_ABROAD[point.value - 1]}
            </Text>
          ))}
        </View>

        {/* Datenbekanntgabe ins Ausland */}
        <View style={pdfStyles.section}>
          <Text style={pdfStyles.heading}>Datenbekanntgabe ins Ausland</Text>
          <Text style={pdfStyles.paragraph}>
          Wir geben Ihre personenbezogenen Daten nicht an Empfänger im Ausland weiter, es sei denn, dies ist gesetzlich vorgeschrieben oder Sie haben ausdrücklich zugestimmt.
          </Text>
        </View>

        {/* Aufbewahrung */}
        <View style={pdfStyles.section}>
          <Text style={pdfStyles.heading}>Dauer der Aufbewahrung</Text>
          <Text style={pdfStyles.paragraph}>
            Wir verarbeiten und speichern Ihre Personendaten, solange es für die Erfüllung unserer vertraglichen und gesetzlichen Pflichten oder sonst die mit der Bearbeitung verfolgten Zwecke erforderlich ist, d.h. also zum Beispiel für die Dauer der gesamten Geschäftsbeziehung (von der Anbahnung, Abwicklung bis zur Beendigung eines Vertrags) sowie darüber hinaus gemäss den gesetzlichen Aufbewahrungs- und Dokumentationspflichten.
          </Text>
        </View>

        {/* Profiling und automatisierte Entscheidfindung*/}
        <View style={pdfStyles.section}>
          <Text style={pdfStyles.heading}>Profiling und automatisierte Entscheidfindung</Text>
          <Text style={pdfStyles.paragraph}>
          Wir verwenden keine automatisierten Entscheidungsfindungsverfahren, 
            einschliesslich Profiling, die rechtliche Auswirkungen auf Sie haben 
            oder Sie in ähnlicher Weise erheblich beeinträchtigen könnten. Sollten 
            wir in Zukunft Profiling einsetzen, erfolgt dies ausschliesslich im Einklang 
            mit den gesetzlichen Vorgaben und nach vorheriger Information sowie, falls 
            erforderlich, Ihrer ausdrücklichen Zustimmung.          </Text>
        </View>

        {/* Datensicherheit */}
        <View style={pdfStyles.section}>
          <Text style={pdfStyles.heading}>Datensicherheit</Text>
          <Text style={pdfStyles.paragraph}>
            Wir haben technische und organisatorische Massnahmen getroffen, um die Sicherheit Ihrer Daten zu gewährleisten. Insbesondere speichern und verarbeiten wir unsere Daten unter hohen Sicherheitsstandards.
          </Text>
          
          {(data.step6?.showToms === "toms" && data.step6?.selectedTomsId) && selectedTomsData && (
            <>
              <Text style={pdfStyles.paragraph}>Unter anderem haben wir folgende Massnahmen umgesetzt:</Text>
              <View style={pdfStyles.tomsGrid}>
                {Object.entries(selectedTomsData)
                  .filter(([key]) => key.includes("step"))
                  .map(([step, stepData], idx) => (
                    <View key={step} style={pdfStyles.tomsContainer}>
                      <Text style={pdfStyles.tomsTitle}>{TITLES[idx]}</Text>
                      {Object.values(stepData).flatMap((points) =>
                        Array.isArray(points)
                          ? points.map((obj, i) => (
                              <Text key={i} style={pdfStyles.listItem}>
                                <Text style={pdfStyles.bulletPoint}>•</Text>
                                {obj.label}
                              </Text>
                            ))
                          : null
                      )}
                    </View>
                  ))}
              </View>
              {selectedTomsData.step12.furtherMeasures && (
                <View style={pdfStyles.furtherInfo}>
                  <Text style={pdfStyles.paragraph}>
                    <Text style={pdfStyles.categoryLabel}>Weitere Massnahmen:</Text>
                    {selectedTomsData.step12.furtherMeasures}
                  </Text>
                </View>
              )}
              {selectedTomsData.step12.certificates && (
                <View style={pdfStyles.furtherInfo}>
                  <Text style={pdfStyles.paragraph}>
                    <Text style={pdfStyles.categoryLabel}>Zertifizierungen:</Text>
                    {selectedTomsData.step12.certificates}
                  </Text>
                </View>
              )}
            </>
          )}
        </View>

        {/* Betroffenenrechte */}
        <View style={pdfStyles.section}>
          <Text style={pdfStyles.heading}>Ihre Rechte als betroffene Person</Text>
          {data.step1.law.value === 1 ? (
            <View>
              <Text style={pdfStyles.paragraph}>Sie haben das Recht:</Text>
              <Text style={pdfStyles.listItem}><Text style={pdfStyles.bulletPoint}>•</Text>auf Auskunft (Art. 25 DSG)</Text>
              <Text style={pdfStyles.listItem}><Text style={pdfStyles.bulletPoint}>•</Text>auf Datenherausgabe und -übertragung (Art. 28 DSG)</Text>
              <Text style={pdfStyles.listItem}><Text style={pdfStyles.bulletPoint}>•</Text>auf Berichtigung (Art. 32 DSG)</Text>
              <Text style={pdfStyles.listItem}><Text style={pdfStyles.bulletPoint}>•</Text>und auf Klagen zum Schutz Ihrer Persönlichkeit (Art. 32 DSG)</Text>
            </View>
          ) : (
            <Text style={pdfStyles.paragraph}>
              Sie haben im Rahmen des auf Sie anwendbaren Datenschutzrechts und soweit darin vorgesehen das Recht auf Auskunft, Berichtigung, Löschung, das Recht auf Einschränkung der Datenbearbeitung und sonst dem Widerspruch gegen unsere Datenbearbeitungen sowie auf Herausgabe gewisser Personendaten zwecks Übertragung an eine andere Stelle.
            </Text>
          )}
        </View>

        {/* Änderungen */}
        <View style={pdfStyles.section}>
          <Text style={pdfStyles.heading}>Änderungen</Text>
          <Text style={pdfStyles.paragraph}>
            Wir können diese Datenschutzerklärung jederzeit ohne Vorankündigung anpassen. Es gilt die jeweils aktuelle, auf unserer Website publizierte Fassung. Soweit die Datenschutzerklärung Teil einer Vereinbarung mit Ihnen ist, werden wir Sie im Falle einer Aktualisierung über die Änderung per E-Mail oder auf andere geeignete Weise informieren.
          </Text>
        </View>

        {/* Letzte Bearbeitung */}
        <Text style={pdfStyles.lastEdited}>Stand: {data.lastEdited.replace(/-/g, ".")}</Text>
      </Page>
    </Document>
  );
};

export { DetailedViewPdf }