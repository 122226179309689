import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

export const predefinedData = [
  { 
    actionId: 1, 
    bereich: "sb.secondtable.verzeichnis.title", 
    description: 'sb.secondtable.verzeichnis.description', 
    completed: 'Nein', 
    overviewlink: "VerzeichnisOverview", 
    createlink: "verzeichnis", 
    uploadlink: "VerzeichnisUpload" 
  },
  { 
    actionId: 2, 
    bereich: "sb.secondtable.br.title", 
    description: 'sb.secondtable.br.description', 
    completed: 'Nein', 
    overviewlink: "BROverview", 
    createlink: "br", 
    uploadlink: "BRUpload" 
  },
  { 
    actionId: 3, 
    bereich: "sb.secondtable.dsfa.title", 
    description: 'sb.secondtable.dsfa.description', 
    completed: 'Nein', 
    overviewlink: "DsfaOverview", 
    createlink: "Datenschutzfolgeabschaetzung", 
    uploadlink: "DSFAUpload" 
  },
  { 
    actionId: 4, 
    bereich: "sb.secondtable.toms.title", 
    description: 'sb.secondtable.toms.description', 
    completed: 'Nein', 
    overviewlink: "TomsOverview", 
    createlink: "toms", 
    uploadlink: "TOMSUpload"  
  },
  { 
    actionId: 5, 
    bereich: "sb.secondtable.dsa.title", 
    description: 'sb.secondtable.dsa.description', 
    completed: 'Nein', 
    overviewlink: "DSAOverview", 
    createlink: "dsa", 
    uploadlink: "DSAUpload" 
  },
  { 
    actionId: 6, 
    bereich: "sb.secondtable.advv.title", 
    description: 'sb.secondtable.advv.description', 
    completed: 'Nein', 
    overviewlink: "advv", 
    createlink: "advv", 
    uploadlink: "advv" 
  },
];

const SecondTable = ({ data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = useMemo(() => [
    {
      Header: t('sb.secondtable.common.area'),
      accessor: 'bereich',
      Cell: ({ value }) => t(value),
    },
    {
      Header: t('sb.secondtable.common.description'),
      accessor: 'description',
      Cell: ({ value }) => t(value),
    },
    {
      Header: t('sb.secondtable.common.fulfilled'),
      accessor: 'completed',
      Cell: ({ value }) => (
        <span style={{ color: value === 'Ja' ? 'green' : 'red' }}>
          {value === 'Ja' ? '✓' : '✗'}
        </span>
      ),
    },
    {
      Header: t('sb.secondtable.common.actions'),
      id: 'actions',
      Cell: ({ row }) => (
        <div style={{ display: 'flex', gap: '10px' }}>
          {row.values.completed === 'Nein' ? (
            <>
              <button 
                className="btn btn-primary accent-dark-grey-bg"
                onClick={() => navigate(`/${row.original.createlink}`)}
              >
                {t('sb.secondtable.common.createNew')}
              </button>
              <button 
                className="btn btn-secondary"
                onClick={() => navigate(`/${row.original.uploadlink}`)}
              >
                {t('sb.secondtable.common.upload')}
              </button>
            </>
          ) : (
            <button 
              className="btn btn-primary accent-dark-grey-bg"
              onClick={() => navigate(`/${row.original.overviewlink}`)}
            >
              {t('sb.secondtable.common.overview')}
            </button>
          )}
        </div>
      ),
    },
  ], [navigate, t]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <table {...getTableProps()} className={`${styles.dsaTable} table-hover`}>
      <thead>
        {headerGroups.map((headerGroup, hgIndex) => {
          const headerGroupProps = headerGroup.getHeaderGroupProps();
          return (
            <tr {...headerGroupProps} key={headerGroupProps.key || hgIndex}>
              {headerGroup.headers.map((column, colIndex) => {
                const headerProps = column.getHeaderProps();
                return (
                  <th
                    {...headerProps}
                    key={column.id || column.accessor || colIndex}
                    className={styles.tableHeading}
                  >
                    {column.render('Header')}
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, rowIndex) => {
          prepareRow(row);
          const rowProps = row.getRowProps();
          return (
            // Use row.id from react-table if available, else fallback to rowIndex
            <tr {...rowProps} key={row.id || rowIndex} className={styles.tableRow}>
              {row.cells.map((cell, cellIndex) => {
                const cellProps = cell.getCellProps();
                // Use cell.column.id if available, else fallback to cellIndex
                return (
                  <td {...cellProps} key={cell.column.id || cellIndex} className={styles.tableData}>
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export { SecondTable };