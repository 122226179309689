import React from 'react';
import { useSortBy, useTable } from 'react-table';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useApi } from 'services/axios';
import { useNavigate } from 'react-router-dom';
import { DeleteModal } from 'components/DeleteModal';
import { useTranslation } from 'react-i18next';
import { SecondTable, predefinedData } from './SecondTable';
import { Navbar, Loader, MobileNavbar, OverviewPdf } from 'components';
import { GlobalDataContext } from '../../../../src/GlobalDataProvider'; // adjust path


import EyeIcon from 'assets/images/eye-icon.svg';
import EditIcon from 'assets/images/edit-icon.svg';
import DeleteIcon from 'assets/images/delete-icon.svg';
import TipBar from 'components/TipBar';
import styles from './styles.module.css';

const normalizeDate = (dateString) => {
  let normalizedDate = dateString;
  if (/^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
    normalizedDate = dateString.replace(/-/g, '.');
  } else if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    const [year, month, day] = dateString.split('-');
    normalizedDate = `${day}.${month}.${year}`;
  }
  return normalizedDate;
};

const Table = ({ data, setShowModal, activeEntry, updateActiveEntry }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation('common');

  const columns = React.useMemo(
    () => [
      {
        Header: 'Active',
        id: 'active',
        Cell: ({ row }) => (
          <input
            type="radio"
            checked={row.values.entryId === activeEntry}
            onChange={() => updateActiveEntry(row.values.entryId)}
          />
        ),
      },
      {
        Header: 'Entry ID',
        accessor: 'entryId',
      },
      {
        Header: t('overview.type'),
        accessor: 'Type',
      },
      {
        Header: t('overview.created'),
        accessor: 'dateCreated',
        Cell: ({ value }) => normalizeDate(value),
      },
      {
        Header: t('overview.edited'),
        accessor: 'lastEdited',
        Cell: ({ value }) => normalizeDate(value),
      },
      {
        Header: t('overview.action'),
        id: 'aktionen',
        Cell: ({ row }) => (
          <span className={styles.actionsWrapper}>
            <img
              src={EyeIcon}
              className={styles.tableIcon}
              onClick={() =>
                navigate(`/StandortbestimmungOverview/${row.values.entryId}`)
              }
            />
            <img
              src={EditIcon}
              className={styles.tableIcon}
              onClick={() =>
                navigate(`/Standortbestimmung/${row.values.entryId}`)
              }
            />
            <img
              src={DeleteIcon}
              className={styles.tableIcon}
              onClick={() => {
                setShowModal(row.values.entryId);
              }}
            />
          </span>
        ),
      },
    ],
    [i18n.language, activeEntry]
  );

  const initialState = { hiddenColumns: ['entryId'] };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data, initialState }, useSortBy);

  return (
    <table {...getTableProps()} className={`${styles.dsaTable} table-hover`}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className={styles.tableHeading}
                onClick={() => {
                  if (column.id !== 'aktionen') {
                    column.toggleSortBy(!column.isSortedDesc);
                  }
                }}
              >
                {column.render('Header')}
                <span>
                  {column.isSorted ? (column.isSortedDesc ? ' \u2193' : ' \u2191') : ''}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className={styles.tableRow}>
              {row.cells.map((cell) => (
                <td
                  {...cell.getCellProps()}
                  className={styles.tableData}
                  style={
                    cell.getCellProps().key.includes('aktionen')
                      ? { width: '40px' }
                      : undefined
                  }
                >
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const StandortbestimmungOverview = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [overviewData, setOverviewData] = React.useState(null);
  const [activeEntry, setActiveEntry] = React.useState(null);
  const [secondTableData, setSecondTableData] = React.useState([]);
  const [pdfFormattedData, setPdfFormattedData] = React.useState(null);
  const [hasStandortbestimmung, sethasStandortbestimmung] = React.useState(false);


  const { api, isApiReady } = useApi();
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const { refreshGlobalData } = React.useContext(GlobalDataContext);


  const updateActiveEntry = async (newActiveEntryId) => {
    try {
      await api.put(`/sb_updateActiveEntry/${newActiveEntryId}`);
      setActiveEntry(newActiveEntryId);
      fetchData(newActiveEntryId);
      refreshGlobalData(); // Trigger a refetch of global data when activeEntry changes
    } catch (error) {
      console.error('Error updating active entry:', error);
    }
  };

  React.useEffect(() => {
    sethasStandortbestimmung(Boolean(activeEntry));
  }, [activeEntry]);

  const fetchOverviewData = async () => {
    const overviewResponse = await api.get('/sb_alldata');
    return overviewResponse.data || [];
  };

  const filterSecondTableDataBasedOnActiveEntry = (activeEntryItem, allData) => {
    let filteredData = [...predefinedData];

    if (!activeEntryItem) {
      return filteredData; // no active entry, just return the base
    }

    if (activeEntryItem.companytypeId === 3) {
      filteredData = filteredData.filter((action) => ![1, 2, 3, 4, 5, 6].includes(action.actionId));
    }
    if (activeEntryItem.serviceTargetId === 4 && activeEntryItem.managingId === 4) {
      filteredData = filteredData.filter((action) => ![1, 2, 3, 4, 5, 6].includes(action.actionId));
    }
    if (activeEntryItem.personalDataId === 2) {
      filteredData = filteredData.filter((action) => ![1, 2, 3, 4, 5, 6].includes(action.actionId));
    }
    if (activeEntryItem.profilingId === 2 && activeEntryItem.protectivePersonalDataId === 2) {
      filteredData = filteredData.filter((action) => ![2, 3].includes(action.actionId));
    }
    if (activeEntryItem.hiringProcessorId === 2 && activeEntryItem.beingProcessorId === 2) {
      filteredData = filteredData.filter((action) => action.actionId !== 6);
    }

    return filteredData;
  };

  const fetchSecondTableCompletionStatus = async (baseData) => {
    // For each predefinedData entry, check if there's data in overview or uploads
    const endpoints = [
      { name: 'verzeichnis', overview: '/verzeichnis_alldata', uploads: '/verzeichnis-uploads', dataKey: 'step1' },
      { name: 'br', overview: '/br_alldata', uploads: '/br-uploads', dataKey: 'entries' },
      { name: 'dsfa', overview: '/dsfa_alldata', uploads: '/dsfa-uploads', dataKey: 'entries' },
      { name: 'toms', overview: '/toms_alldata', uploads: '/toms-uploads', dataKey: 'entries' },
      { name: 'dsa', overview: '/dsa_alldata', uploads: '/dsa-uploads', dataKey: null },
      { name: 'advv', overview: null, uploads: '/advv-uploads', dataKey: null },
    ];

    const results = await Promise.all(
      endpoints.map(async ({ name, overview, uploads, dataKey }) => {
        const [overviewResponse, uploadsResponse] = await Promise.all([
          overview ? api.get(overview) : Promise.resolve({ data: [] }),
          api.get(uploads)
        ]);

        let overviewData = overviewResponse.data || [];
        if (dataKey && overviewData[dataKey]) {
          overviewData = overviewData[dataKey];
        }

        const hasData = (overviewData && overviewData.length > 0) || 
                        (uploadsResponse.data && uploadsResponse.data.length > 0);

        return {
          name,
          completed: hasData ? 'Ja' : 'Nein',
        };
      })
    );

    const nameToCompletion = results.reduce((acc, curr) => {
      acc[curr.name] = curr.completed;
      return acc;
    }, {});

    return baseData.map((item) => {
      const key = item.createlink; 
      const completed = nameToCompletion[key] || 'Nein';
      return { ...item, completed };
    });
  };

  const fetchData = async (activeEntryIdFromUpdate = null) => {
    if (!isApiReady()) return;
  
    try {
      const allOverviewData = await fetchOverviewData();
      setOverviewData(allOverviewData);
  
      const activeEntryItem = allOverviewData.find((entry) => entry.active === 1) || null;
      const finalActiveEntryId = activeEntryIdFromUpdate || activeEntryItem?.entryId || null;
      setActiveEntry(finalActiveEntryId);
  
      if (!activeEntryItem) {
        // No active Standortbestimmung, so second table should be empty
        setSecondTableData([]);
        return;
      }
  
      const filteredData = filterSecondTableDataBasedOnActiveEntry(activeEntryItem, allOverviewData);
      const completedData = await fetchSecondTableCompletionStatus(filteredData);
      setSecondTableData(completedData);
    } catch (error) {
      console.error('Error fetching data:', error);
      setOverviewData([]);
      setActiveEntry(null);
      setSecondTableData([]);
    }
  };

  const handleDelete = async () => {
    await api.delete(`/sb_alldata/${showModal}`);
    fetchData();
    setShowModal(false);
  };

  React.useEffect(() => {
    if (isApiReady && overviewData === null) {
      fetchData();
    }
  }, [isApiReady]);

  return (
    <div
      className="wrapper"
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100vh',
      }}
    >
      <TipBar secondTableData={secondTableData} hasStandortbestimmung={hasStandortbestimmung} />
      <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
        <Navbar />
        <MobileNavbar />
        <div
          className={styles.overviewContainer}
          style={{ overflow: 'auto' }}
          id="content"
        >
          {/* First Table */}
          <div
            className={`card morph_variation1 padder ${styles.overviewCard}`}
            style={{ gap: '25px' }}
          >
            <div className={styles.overviewHeadingWrapper}>
              <h4 className={styles.overviewHeading}>{t('overview.sb.title')}</h4>
              <div className={styles.headingBtnWrapper}>
                <button
                  className="btn btn-primary accent-dark-grey-bg"
                  onClick={() => navigate('/Standortbestimmung')}
                >
                  {t('overview.createBtn')}
                </button>
                <PDFDownloadLink
                  document={
                    <OverviewPdf
                      data={pdfFormattedData}
                      title="Standortbestimmungen"
                    />
                  }
                  fileName="Standortbestimmungen_Übersicht.pdf"
                >
                  {({ loading }) => (
                    <button className="btn btn-default">
                      {loading ? 'Loading document...' : t('overview.downloadPDF')}
                    </button>
                  )}
                </PDFDownloadLink>
              </div>
            </div>
            <div style={{ overflowX: 'auto' }}>
              {overviewData ? (
                <Table
                  data={overviewData}
                  setShowModal={setShowModal}
                  activeEntry={activeEntry}
                  updateActiveEntry={updateActiveEntry}
                />
              ) : (
                <Loader />
              )}
            </div>
            {showModal && (
              <DeleteModal
                onCancel={() => setShowModal(false)}
                onConfirm={handleDelete}
              />
            )}
          </div>

          {/* Second Table */}
          <div
            className={`card morph_variation1 padder ${styles.overviewCard}`}
            style={{ gap: '25px' }}
          >
            <div className={styles.overviewHeadingWrapper}>
              <h4 className={styles.overviewHeading}>{t('overview.sb.secondtitle')}</h4>
            </div>
            <div style={{ overflowX: 'auto' }}>
              {secondTableData && secondTableData.length > 0 ? (
                <SecondTable data={secondTableData} />
              ) : (
                <span>{t('overview.sb.seconddescription')}</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { StandortbestimmungOverview };