import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import { Dsa_Step1 } from '../steps/Dsa_Step1';
import { Dsa_Step2 } from '../steps/Dsa_Step2';
import { Dsa_Step3 } from '../steps/Dsa_Step3';
import { Dsa_Step4 } from '../steps/Dsa_Step4';
import { Dsa_Step5 } from '../steps/Dsa_Step5';
import { Dsa_Step6 } from '../steps/Dsa_Step6';
// import { Dsa_Step7 } from '../steps/Dsa_Step7';
// import { Dsa_Step8 } from '../steps/Dsa_Step8';
// import { Dsa_Step9 } from '../steps/Dsa_Step9';


const DsaFormular = ({ next, back, activeStep }) => {
  const { id } = useParams();
  const { formState } = useFormContext();
  const { t } = useTranslation('common');

  const handleSaveAndExit = () => {
    if (id && !Object.keys(formState.errors)?.length) {
      sessionStorage.setItem(`saveDsaEntry-${id}`, 'true');
    }
  };

  return (
    <div className="morph_variation1 padder" style={{ overflow: 'visible' }}>
      <div className={styles.formularHeading}>
        <h3 style={{ marginBottom: '20px' }}>{t('dsa.title')}</h3>
        {id && (
          <button
            className="btn btn-primary accent-orange-bg"
            type="submit"
            onClick={handleSaveAndExit}
            form="dsa-form"
          >
            {t('saveAndExit')}
          </button>
        )}
      </div>
      {activeStep < 7 && (
        <div
          className="progress"
          style={{ height: '30px', marginTop: '20px', marginBottom: '20px' }}
        >
          <div
            className="progress-bar"
            role="progressbar"
            style={{
              width: `${(activeStep / 6) * 100}%`,
              backgroundColor: '#fa8231',
            }}
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {activeStep} / 7
          </div>
        </div>
      )}
      {activeStep === 1 && <Dsa_Step1 />}
      {activeStep === 2 && <Dsa_Step2 />}
      {activeStep === 3 && <Dsa_Step3 />}
      {activeStep === 4 && <Dsa_Step4 />}
      {activeStep === 5 && <Dsa_Step5 />}
      {activeStep === 6 && <Dsa_Step6 />}
      {/* {activeStep === 7 && <Dsa_Step7 />}
      {activeStep === 8 && <Dsa_Step8 />}
      {activeStep === 9 && <Dsa_Step9 />} */}
      {activeStep >= 7 && <h5>{t('verzeichnis.processing')}</h5>}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          marginTop: '60px',
        }}
        className={styles.navBtns}
      >
        <button
          onClick={back}
          type="button"
          className="btn btn-secondary"
          style={{ marginBottom: '10px', marginTop: '16px', width: '200px' }}
        >
          {t('back')}
        </button>
        <button
          onClick={next}
          type={activeStep === 7 ? 'submit' : 'button'}
          className="btn btn-primary accent-orange-bg"
          style={{ marginBottom: '10px', marginTop: '16px', width: '200px' }}
          form="dsa-form"
        >
          {activeStep === 6 ? t('saveAndExit') : t('saveAndContinue')}
        </button>
      </div>
    </div>
  );
};

export { DsaFormular };
