import React from 'react';
import { useSortBy, useTable } from 'react-table';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useApi } from 'services/axios';
import { useNavigate } from 'react-router-dom';
import { DeleteModal } from 'components/DeleteModal';
import { useTranslation } from 'react-i18next';
import { Navbar, Loader, MobileNavbar, OverviewPdf, UploadsTable } from 'components';
import { BlobServiceClient } from '@azure/storage-blob';
import { azureStorageConfig } from 'msalConfig';
import { getStorageEnv } from 'services/getStorageEnv';

import EyeIcon from 'assets/images/eye-icon.svg';
import EditIcon from 'assets/images/edit-icon.svg';
import DeleteIcon from 'assets/images/delete-icon.svg';

import styles from './styles.module.css';
import TipBar from 'components/TipBar';

const normalizeDate = (dateString) => {
  let normalizedDate = dateString;
  if (/^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
    normalizedDate = dateString.replace(/-/g, '.');
  } else if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    const [year, month, day] = dateString.split('-');
    normalizedDate = `${day}.${month}.${year}`;
  }
  return normalizedDate;
};

const StatusCell = ({ value, combinerValue, type, entryId, onStatusChange, options }) => {
  const [selectedOption, setSelectedOption] = React.useState(null);

  React.useEffect(() => {
    if (combinerValue !== null) {
      const currentOption = options.find(option => option.id === combinerValue);
      setSelectedOption(currentOption || null);
    } else {
      setSelectedOption(null);
    }
  }, [combinerValue, options]);

  const handleChange = (event) => {
    const newOptionId = parseInt(event.target.value);
    const newOption = options.find(option => option.id === newOptionId);
    setSelectedOption(newOption);
    onStatusChange(entryId, type, newOptionId);
  };

  if (value === 0) {
    return <span>nicht benötigt</span>;
  } else if (options.length > 0) {
    return (
      <select 
        value={selectedOption ? selectedOption.id : ''}
        onChange={handleChange} 
        className={styles.statusDropdown}
      >
        <option value="">bitte auswählen</option>
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
    );
  } else {
    return <span>benötigt, bitte separat erstellen</span>;
  }
};

const Table = ({ data, setShowModal, companyData, onStatusChange, brOptions, dsfaOptions }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation('common');

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: t('overview.verzeichnis.designation'),
        accessor: 'bezeichnung',
      },
      {
        Header: t('overview.purpose'),
        accessor: 'zweck',
      },
      {
        Header: t('overview.verzeichnis.schuetzenswert1'),
        accessor: 'schuetzenswert',
      },
      {
        Header: t('overview.br-title'),
        accessor: 'br',
        Cell: ({ value, row }) => (
          <StatusCell 
            value={value}
            combinerValue={row.original.brCombinerValue}
            type="br" 
            entryId={row.original.id} 
            onStatusChange={onStatusChange}
            options={brOptions}
          />
        ),
      },   
      {
        Header: t('overview.dsfa-title'),
        accessor: 'dsfa',
        Cell: ({ value, row }) => (
          <StatusCell 
            value={value}
            combinerValue={row.original.dsfaCombinerValue}
            type="dsfa" 
            entryId={row.original.id} 
            onStatusChange={onStatusChange}
            options={dsfaOptions}
          />
        ),
      },
      {
        Header: t('overview.edited'),
        accessor: 'lastEdited',
        Cell: ({ value }) => normalizeDate(value),
        sortType: (a, b) => {
          const parseDate = (dateString) => {
            const [day, month, year] = normalizeDate(dateString).split('.');
            return `${year}-${month}-${day}`;
          };
          const dateA = parseDate(a.original.lastEdited);
          const dateB = parseDate(b.original.lastEdited);
          return dateA.localeCompare(dateB);
        }
      },
      {
        Header: t('overview.action'),
        id: 'aktionen',
        Cell: ({ row }) => (
          <span className={styles.actionsWrapper}>
            <img
              src={EyeIcon}
              className={styles.tableIcon}
              onClick={() => navigate(`/VerzeichnisOverview/${row.values.id}`)}
            />
            <img
              src={EditIcon}
              className={styles.tableIcon}
              onClick={() => navigate(`/Verzeichnis/${row.values.id}`)}
            />
            <img
              src={DeleteIcon}
              className={styles.tableIcon}
              onClick={() => {
                setShowModal(row.values.id);
              }}
            />
          </span>
        ),
      },
    ],
    [i18n.language, onStatusChange, brOptions, dsfaOptions]
  );

  const initialState = { hiddenColumns: ['id'] };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data, initialState }, useSortBy);

  return (
    <table
      {...getTableProps()}
      className={`${styles.verzeichnisTable} table-hover`}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className={styles.tableHeading}
                onClick={() => {
                  if (column.id !== 'aktionen') {
                    column.toggleSortBy(!column.isSortedDesc);
                  }
                }}
              >
                {column.render('Header')}
                <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' \u2193'
                      : ' \u2191'
                    : ''}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className={styles.tableRow}>
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className={styles.tableData}
                    style={
                      cell.getCellProps().key.includes('aktionen')
                        ? { width: '40px' }
                        : undefined
                    }
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const VerzeichnisOverview = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [showUploadsModal, setShowUploadsModal] = React.useState(false);
  const [overviewData, setOverviewData] = React.useState(null);
  const [companyData, setCompanyData] = React.useState(null);
  const [uploadsData, setUploadsData] = React.useState(null);
  const [meData, setMeData] = React.useState(null);
  const [pdfFormattedData, setPdfFormattedData] = React.useState(null);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [brOptions, setBrOptions] = React.useState([]);
  const [dsfaOptions, setDsfaOptions] = React.useState([]);
  const { api, isApiReady } = useApi();
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  // Container client state
  const [containerClient, setContainerClient] = React.useState(null);

  const fetchMe = async () => {
    if (meData) return;
    try {
      const response = await api.get('/me');
      console.log("meData fetched:")
      console.log(response.data)

      setMeData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  React.useEffect(() => {
    if (api && !meData) {
      fetchMe();
    }
  }, [api, meData]);

  // Initialize container client once meData is available
  React.useEffect(() => {
    if (meData) {
      const blobClient = new BlobServiceClient(
        `https://${azureStorageConfig.ACCOUNT}.blob.core.windows.net/?${azureStorageConfig.SAS_TOKEN}`
      );
      const client = blobClient.getContainerClient(
        `user${meData.id}-${getStorageEnv()}`
      );
      console.log("Client:")
      console.log(client)

      setContainerClient(client);

    }
  }, [meData]);

  const fetchData = async () => {
    if (dataLoaded) return;

    try {
      const [overviewResponse, companyResponse, uploadsResponse, brResponse, dsfaResponse, combinerResponse] = await Promise.all([
        api.get('/verzeichnis_alldata'),
        api.get('/companydata'),
        api.get('/verzeichnis-uploads'),
        api.get('/br_alldata'),
        api.get('/dsfa_alldata'),
        api.get('/verzeichnis-combiner-data')
      ]);

      setUploadsData(uploadsResponse.data);
      setCompanyData(companyResponse.data[0]);
      
      const brOps = brResponse.data.entries.map(entry => ({
        id: entry.id,
        name: entry.description
      }));
      setBrOptions(brOps);

      const dsfaOps = dsfaResponse.data.entries.map(entry => ({
        id: entry.id,
        name: entry.purpose
      }));
      setDsfaOptions(dsfaOps);

      const { brCombinerData, dsfaCombinerData } = combinerResponse.data;

      const updatedOverviewData = overviewResponse.data.step1.map(entry => {
        const brCombiner = brCombinerData.find(c => c.VerzeichnisEntryId === entry.id);
        const dsfaCombiner = dsfaCombinerData.find(c => c.VerzeichnisEntryId === entry.id);
        return {
          ...entry,
          brCombinerValue: brCombiner ? brCombiner.BREntryId : null,
          dsfaCombinerValue: dsfaCombiner ? dsfaCombiner.DSFAEntryId : null
        };
      });

      setOverviewData(updatedOverviewData);
      setDataLoaded(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  React.useEffect(() => {
    if (isApiReady && !dataLoaded) {
      fetchData();
    }
  }, [isApiReady, dataLoaded]);

  const handleDelete = async () => {
    await api.delete(`/verzeichnis_alldata/${showModal}`);
    fetchData();
    setShowModal(false);
  };

  const handleDeleteUploads = async () => {
    if (!showUploadsModal.name || !containerClient) return;

    const options = {
      deleteSnapshots: 'include',
    };

    const blockBlobClient = containerClient.getBlockBlobClient(
      `Verzeichnis/${showUploadsModal.name}`
    );
    await blockBlobClient.deleteIfExists(options);
    await api.delete(`/verzeichnis-uploads/${showUploadsModal.id}`);

    fetchData();
    setShowUploadsModal(false);
  };

  const handleDownload = React.useCallback(async (filename) => {
    if (!containerClient) {
      console.log("ContainerClient not ready, cannot download.");
      return;
    }
  
    const blockBlobClient = containerClient.getBlockBlobClient(`Verzeichnis/${filename}`);
    try {
      const blobUrl = blockBlobClient.url;
      const anchor = document.createElement('a');
      anchor.style.display = 'none';
      document.body.appendChild(anchor);
  
      anchor.href = blobUrl;
      anchor.download = filename;
  
      anchor.click();
      document.body.removeChild(anchor);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  }, [containerClient]);

  const handleStatusChange = async (entryId, type, newStatusId) => {
    setOverviewData(prevData => 
      prevData.map(entry => 
        entry.id === entryId 
          ? { ...entry, [`${type}CombinerValue`]: newStatusId } 
          : entry
      )
    );
    
    try {
      await api.post('/verzeichnis-combiner', {
        verzeichnisEntryId: entryId,
        type: type,
        selectedEntryId: newStatusId
      });
    } catch (error) {
      console.error('Error updating verzeichnis combiner:', error);
    }
  };

  const renderTable = () => {
    if (overviewData?.length === 0) {
      return <span>{t('noDataFound')}</span>;
    }

    if (!overviewData) {
      return <Loader />;
    }

    return (
      <Table
        data={overviewData}
        companyData={companyData}
        setShowModal={setShowModal}
        onStatusChange={handleStatusChange}
        brOptions={brOptions}
        dsfaOptions={dsfaOptions}
      />
    );
  };

const renderUploadsTable = () => {
  if (!containerClient) {
    // Show a loading state or nothing until containerClient is ready
    return <span>Loading download client...</span>;
  }

  if (!uploadsData) {
    return <Loader />;
  }

  if (uploadsData.length === 0) {
    return <span>{t('noDataFound')}</span>;
  }

  return (
    <UploadsTable
      data={uploadsData}
      setShowModal={setShowUploadsModal}
      handleDownload={handleDownload}
    />
  );
};

  React.useEffect(() => {
    if (overviewData) {
      setPdfFormattedData({
        columns: [ 'Bezeichnung', 'Zweck', 'Schuetzenswert', 'Erstellt', ],
        data: overviewData?.map((entry) => {
          return {
            Erstellt: entry.dateCreated.replace(/-/g, '.'),
            Bezeichnung: entry.bezeichnung,
            Zweck: entry.zweck,
            Schuetzenswert: entry.schuetzenswert,
          };
        }),
      });
    }
  }, [overviewData]);

  return (
    <div className="wrapper" style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh' }}>
      <TipBar />
      <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
        <Navbar />
        <MobileNavbar />
        <div className={styles.overviewContainer} style={{ overflow: 'auto' }} id="content">
          <div className={`card morph_variation1 padder ${styles.overviewCard}`} style={{ gap: '25px' }}>
            <div className={styles.overviewHeadingWrapper}>
              <h4 className={styles.overviewHeading}>
                {t('overview.verzeichnis.title')}
              </h4>
              
              <div className={styles.headingBtnWrapper}>
                <button className="btn btn-primary" onClick={() => navigate('/Verzeichnis')}>
                  {t('overview.createBtn')}
                </button>
                <PDFDownloadLink
                  document={<OverviewPdf data={pdfFormattedData} title=" Bearbeitungsverzeichnis" />}
                  fileName="Bearbeitungsverzeichnis.pdf"
                >
                  {({ loading }) => (
                    <button className="btn btn-default">
                      {loading ? 'Loading document...' : t('overview.downloadPDF')}
                    </button>
                  )}
                </PDFDownloadLink>
              </div>
            </div>
            <div style={{ overflowX: 'auto' }}>{renderTable()}</div>
            {showModal && <DeleteModal onCancel={() => setShowModal(false)} onConfirm={() => handleDelete()} />}
          </div>
  
          <div className={`card morph_variation1 padder ${styles.overviewCard}`} style={{ gap: '25px' }}>
            <div className={styles.overviewHeadingWrapper}>
              <h4 className={styles.overviewHeading}>
                {t('overview.verzeichnis.data')}
              </h4>
              <div className={styles.headingBtnWrapper}>
                <button className="btn btn-primary" onClick={() => navigate('/VerzeichnisUpload')}>
                  {t('overview.upload')}
                </button>
              </div>
            </div>
            <div style={{ overflowX: 'auto' }}>{renderUploadsTable()}</div>
            {showModal && <DeleteModal onCancel={() => setShowModal(false)} onConfirm={() => handleDelete()} />}
            {showUploadsModal && (
              <DeleteModal onCancel={() => setShowUploadsModal(false)} onConfirm={() => handleDeleteUploads()} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { VerzeichnisOverview };