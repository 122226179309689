import React from 'react';
import { Image,Page,Text,View,Document,StyleSheet } from '@react-pdf/renderer';
import Logo_Red_New from '../../../../src/assets/images/Logo_Red_New.png';
import { useTranslation } from 'react-i18next';
import { TITLES } from '.';

const DetailedViewPdf = ({ data }) => {
  console.log('pdf data: ', data);
  const { companyInfo } = data;
  const { t } = useTranslation('common');

  const pdfStyles = StyleSheet.create({
    pageContainer: { paddingVertical: 40, paddingHorizontal: 20 },
    logoContainer: { flexDirection: 'row', justifyContent: 'flex-end', padding: 10 },
    logo: { textAlign: 'right', width: '30%', marginTop: '-20px' },
    documenttitle: { fontSize: 13, textAlign: 'left', padding: 2, marginBottom: 5 },
    documentsubtitle: { fontSize: 9, textAlign: 'left', padding: 2, marginBottom: 4 },
    documentdates: { fontSize: 9, textAlign: 'left', padding: 2, marginBottom: 4, marginTop: 15 },
    documentcompanydata: { fontSize: 11, textAlign: 'left', padding: 2, marginBottom: 45,},
    section: { display: 'flex', flexDirection: 'column', marginBottom: 5 },
    outerPadding: { padding: 40 },
    sectionTitle: { fontSize: 11, fontWeight: 'bold', marginLeft: 10, marginBottom: 5 },
    rowView: { fontSize: 10, display: 'flex', marginLeft: 5 },
    rowOdd: { backgroundColor: 'rgb(232, 232, 232)' },
    tableHeaders: { backgroundColor: '#d33c2e', color: '#fff', marginTop: -1, fontWeight: '700' },
    mainPointTitle: { fontSize: 11, marginLeft: 5, marginBottom: 2, marginTop: 5 },
    mainPointValue: { width: '70%' },
    categoryPointList: { fontSize: 10, display: 'flex', flexDirection: 'column' },
    points: { marginLeft: 9 },
    columnContainer: { flexDirection: 'row', justifyContent: 'space-between' },
    column1: {width: '50%', paddingRight: 5},
    column2: {width: '50%', paddingLeft: 5},
    border: { borderTopColor: 'grey', borderTopWidth: 1, marginBottom: 5, marginTop: 5 },
  });

  return (
    <Document>
      <Page size="A4" style={pdfStyles.pageContainer}>
        {/* <View style={pdfStyles.logoContainer}>
          <Image style={pdfStyles.logo} source={Logo_Red_New} />
        </View> */}

        {data && (
          <View style={pdfStyles.outerPadding}>


            <View style={pdfStyles.documentcompanydata}>
              <Text>{companyInfo?.CompanyName}</Text>
              <Text>
                {companyInfo?.CompanyStreet} {companyInfo?.CompanyStreetNr}
              </Text>
              <Text>
                {companyInfo?.CompanyZIP} {companyInfo?.CompanyCity}
              </Text>
            </View>
            
            <View style={pdfStyles.documenttitle}>
              <Text>
                {t('detailedView.toms')}
              </Text>
            </View>

            <View
              style={{
                borderTopColor: 'grey',
                borderTopWidth: '1px',
                marginBottom: 10,
              }}
            />

            {Object.keys(data).map((step, idx) =>
              step.includes('step') ? (
                <View style={pdfStyles.section} key={step}>
                  <View>
                    {Object.keys(data[step]).map((point) => (
                      <View
                        style={{
                          ...pdfStyles.rowView,
                          flexDirection: 'column',
                        }}
                        key={idx}
                      >
                        <Text style={pdfStyles.mainPointTitle}>
                          {TITLES[idx]}
                        </Text>
                        <View className={pdfStyles.categoryPointList}>
                          {Array.isArray(data[step][point])
                            ? data[step][point].map((obj, i) => (
                                <View
                                  style={{
                                    flexDirection: 'row',
                                    marginBottom: 1,
                                  }}
                                  key={i}
                                >
                                  <Text
                                    style={{ marginLeft: 20, marginRight: 8 }}
                                  >
                                    •
                                  </Text>
                                  <Text>{obj.label}</Text>
                                </View>
                              ))
                            : null}
                        </View>
                      </View>
                    ))}
                  </View>
                </View>
              ) : null
            )}

            <View
              style={{
                borderTopColor: 'grey',
                borderTopWidth: '1px',
                marginBottom: 10,
              }}
            />

            <View style={pdfStyles.section}>
              <View>
                <Text style={pdfStyles.mainPointTitle}>Weitere Massnahmen</Text>
                <View style={pdfStyles.rowView}>
                  <Text style={pdfStyles.points}>
                    {data.step12.furtherMeasures}
                  </Text>
                </View>
              </View>

              <View
              style={{
                marginBottom: 5,
                marginTop: 5,
              }}
            />

              <View>
                <Text style={pdfStyles.mainPointTitle}>Zertifizierungen</Text>
                <View style={pdfStyles.rowView}>
                  <Text style={pdfStyles.points}>
                    {data.step12.certificates}
                  </Text>
                </View>
              </View>

              <View style={pdfStyles.documentdates}>
              <Text>
                {t('detailedView.dateCreated')}: {data.dateCreated}
              </Text>
              <Text>
                {t('detailedView.lastEdited')}: {data.lastEdited}
              </Text>
            </View>

            </View>
          </View>
        )}
      </Page>
    </Document>
  );
};

export { DetailedViewPdf };
